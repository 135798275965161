import { Component } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent {
  showCookieBar = !localStorage.getItem('cookiesAccepted');

  constructor(public _translationService: TranslationsService) {}

  acceptCookies(): void {
    localStorage.setItem('cookiesAccepted', 'true');
    this.showCookieBar = false;
  }
}
