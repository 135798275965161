<div class="cookie-consent-bar" *ngIf="showCookieBar">
  <div class="cookie-icon">
    <img src="assets/cookie-notice.jpg" alt="Cookie Icon" />
  </div>
  <div class="cookie-message">
    <span>{{_translationService.getTranslations().about.cookie}}</span>
  </div>
  <div class="cookie-action">
    <button (click)="acceptCookies()">OK</button>
  </div>
</div>
