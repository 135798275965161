import { Component, HostListener, OnInit } from '@angular/core';
import { TranslationsService } from './translations/translations.service';
import { Router } from '@angular/router';
import { RemoteDataService } from './remote-data.service';
import { MetaTitleService } from './meta-title.service';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Subscription } from 'rxjs';
import { ScrollService } from './scroll.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public lang: string;
  headerHasWhiteBackground = false;
  private scrollSubscription: Subscription;
  private imageHeight = 0;

  constructor(
    public _translationsService: TranslationsService,
    private router: Router, private remoteDataService: RemoteDataService,
    private metaTitleService: MetaTitleService, private _scrollService: ScrollService) {
  }

  ngOnInit() {
    this.remoteDataService.loadPosts();
    this.remoteDataService.loadIllustrations();

    this.metaTitleService.updateTag('description', 'Сайт автора и иллюстратора детских книг Иры Шиловой.');
    this.metaTitleService.updateTag('title', 'Ира Шилова');
    this.metaTitleService.updateTag('type', 'article');
    this.metaTitleService.updateTag('url', 'ira-shilova.com');
    this.metaTitleService.updateTag('image', '/assets/bio/bio_main_photo.webp');
    this.metaTitleService.updateTag('site_name', 'Ira Shilova');

    const analytics = getAnalytics();
    logEvent(analytics, 'website loaded');
  }

  // @HostListener('scroll', ['$event'])
  //   onScroll(event) {
  //     let topRef = document.getElementById("top");
  //     let headerRef = document.getElementById("headerRef");

  //     console.log(window.location.href.includes('about'));
  //     if (window.location.href.includes('about')) {
  //       if (topRef.getBoundingClientRect().height - (topRef.getBoundingClientRect().top * -1) < 50) {
  //         console.log(topRef.getBoundingClientRect());
  //         console.log(headerRef);
  //         headerRef.style.removeProperty('background');
  //         headerRef.style.setProperty('background', 'rgba(255, 255, 255, 1)');
  //       }
  //       if (topRef.getBoundingClientRect().height - (topRef.getBoundingClientRect().top * -1) > 50) {
  //         console.log(topRef.getBoundingClientRect());
  //         console.log(headerRef);
  //         headerRef.style.removeProperty('background');
  //         headerRef.style.setProperty('background', 'rgba(255, 255, 255, 0,127)');
  //       }
  //     } else {
  //       headerRef.style.removeProperty('background');
  //       headerRef.style.setProperty('background', 'rgba(255, 255, 255, 1)');
  //     }
  //   }

  changeLang(val: string) {
    this._translationsService.setLocal(val);
  }

  menuClick() {
    document.getElementById('nav-toggle-action').click();
  }

  onActivate(event: Event) {
    let el = document.getElementById('anch');
    el.scrollIntoView();
  }
}
