import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BlogModule } from './blog/blog.module';
import { CommonModule } from '@angular/common';
import { IllustrationsModule } from './illustrations/illustrations.module'
import { BookModule } from './book/book.module';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';
import { AboutModule } from './about/about.module';

import { HttpClientModule} from '@angular/common/http';
import { SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { provideFirebaseApp, initializeApp }
  from '@angular/fire/app';
import { getAuth, provideAuth }
  from '@angular/fire/auth';
import { getFirestore, provideFirestore }
  from '@angular/fire/firestore';
import { getAnalytics, provideAnalytics }
  from '@angular/fire/analytics';
import { getStorage, provideStorage }
  from '@angular/fire/storage';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';

@NgModule({
  declarations: [
    AppComponent,
    CookieConsentComponent,
  ],
  imports: [
    QuicklinkModule,
    BookModule,
    IllustrationsModule,
    BrowserModule,
    CommonModule,
    BlogModule,
    AboutModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideStorage(() => getStorage()),
    HttpClientModule,
    RouterModule.forRoot([
      {
        path: 'about',
        loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
      },
      { path: '**', redirectTo: '/about', pathMatch: 'full' },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/about'
      },
      {
        path: 'blog',
        loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule),
      },
      {
        path: 'illustrations',
        loadChildren: () => import('./illustrations/illustrations.module').then(m => m.IllustrationsModule),
      },
      {
        path: 'book',
        loadChildren: () => import('./book/book.module').then(m => m.BookModule),
      }
    ],
      { preloadingStrategy: QuicklinkStrategy},
    ),
  ],
  providers: [
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: true }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
